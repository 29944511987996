import React from 'react'


import ExternalLink from "./external-link"

function CodeRepository({url, title = "Github Repository"}) {
  return (
    <p>
      You can find all the code related to this post in this &nbsp;
      <ExternalLink
        url={url}
        title={title} />
    </p>
  )
}

export default CodeRepository
