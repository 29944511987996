import React from "react"
import Github from "../../svgs/github.svg"
import { Box, Flex, Link } from "@chakra-ui/react"

function GithubLink({ url, title = "See diff" }) {
  return (
    <Box mb="2">
      <Link target="_blank" href={url}>
        <Flex
          align="center"
        >
          <Box
            as={Github}
            maxW="20px"
            filter="invert(21%) sepia(23%) saturate(5174%) hue-rotate(252deg) brightness(96%) contrast(90%)"
          />
          <Box
            flexShrink="0"
            ml="2"
          >{title}
          </Box>
        </Flex>
      </Link>
    </Box>
  )
}

export default GithubLink
