import React from "react"
import { Button, Flex, Link, Spacer, Text, useColorMode } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import {MoonIcon, SunIcon} from "@chakra-ui/icons"

function Header() {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Flex
      as="header"
      my="7"
    >
      <Text
        fontSize={28}
      >
        <Link>
          <GatsbyLink
            to="/">
            <Text as="u">
              KAJAN M
            </Text>
          </GatsbyLink>
        </Link>
      </Text>
      <Spacer />

      <Button onClick={toggleColorMode}>
        {colorMode == 'light' ? <SunIcon /> : <MoonIcon />}
      </Button>
    </Flex>
  )
}

export default Header
