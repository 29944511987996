import React from "react"
import { Link } from "@chakra-ui/react"


function ExternalLink({ url, title, ...props }) {
  return (
    title ?
      (<Link textDecoration="underline" href={url} target="_blank">{title}</Link>) : (
        <Link textDecoration="underline" d="flex" align="center" justify="center" href={url} target="_blank" {...props} />)
  )
}

export default ExternalLink
