import React from "react"
import { Divider, ListItem, OrderedList, Text, UnorderedList } from "@chakra-ui/react"
import { Code, Heading, Highlight } from "./typography"
import PrismCore from "prismjs/components/prism-core.min"
import './styles/prism-styles'
import ChakraUIPrism from "./styles/code"
import Link from "./link"

function Paragraph(props) {
  return <Text mb={4} {...props} />
}

const headings = {
  h1: props => <Heading as="h1" {...props} />,
  h2: props => <Heading as="h2" {...props} />,
  h3: props => <Heading as="h3" {...props} />,
  h4: props => <Heading as="h4" {...props} />,
  h5: props => <Heading as="h5" {...props} />,
  h6: props => <Heading as="h6" {...props} />,
  p: Paragraph,
}

const components = {
  pre: (props) => props.children,
  code: props => <ChakraUIPrism {...props} Prism={PrismCore} />,
  Code: props => <Code {...props} />,
  Highlight: props => <Highlight {...props} />,
  HighlightBlock: props => <Highlight {...props} d="block" my="3" />,
  ol: props => <OrderedList {...props} />,
  ul: props => <UnorderedList {...props} />,
  li: props => <ListItem mt={2} {...props} />,
  hr: props => <Divider {...props} />,
  a: props => <Link {...props} />,
  ...headings
}

export default components
