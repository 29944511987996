import React from "react"

// import Header from "gatsby-theme-blog/src/components/header"
// import useBlogThemeConfig from "gatsby-theme-blog/src/hooks/configOptions"
import Helmet from "react-helmet"
// import { SkipNavContent } from "@reach/skip-nav"
import { MDXProvider } from "@mdx-js/react"
import GithubLink from "./github-link"
import ExternalLink from "./external-link"
import CodeRepository from "./code-repository"
import {
  Alert as ChakraUiAlert,
  Container,
  Box,
  ChakraProvider,
  AlertIcon,
  Text,
  extendTheme,
  OrderedList, ListItem, Flex
} from "@chakra-ui/react"
import mdxComponents from "./mdx-components"
import Header from "./header"

const Layout = ({ children, ...props }) => {
  // const blogThemeConfig = useBlogThemeConfig()
  // const { webfontURL } = blogThemeConfig
  const shortcodes = {
    GithubLink,
    ExternalLink,
    CodeRepository,
    Box,
    Text,
    Flex: props => <Flex {...props} />,
    Emphasis: props => <Text as="em" {...props} />,
    Strong: props => <Box as="strong" fontWeight="bold" {...props} />,
    StrikeThrough: props => <Text as="s" {...props} />,
    Alert: ({ children, ...props }) => <ChakraUiAlert my="4" status="info" {...props}><AlertIcon />{children}</ChakraUiAlert>,
    OrderedList: props => <OrderedList {...props} />,
    ListItem: props => <ListItem {...props} />,
    ...mdxComponents
  }

  const theme = extendTheme({
    styles: {
      global: {
        html: {
          scrollBehavior: "smooth"
        }
      }
    }
  })

  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      {/*<Header {...props} />*/}
      {/*<SkipNavContent />*/}
      <Container
        maxW="container.md"
      >
        <Header />
        <MDXProvider components={shortcodes}>
          {children}
        </MDXProvider>
      </Container>
    </ChakraProvider>
  )
}

export default Layout
