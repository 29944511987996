import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import DebugData from "./debug-data"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Flex, Image } from "@chakra-ui/react"
import BioContent from './bio-content'

const Bio = () => {
  const data = useStaticQuery(myBioQuery)
  const {
    site: {
      siteMetadata: { author },
    },
    avatar,
  } = data

  return (
      <Flex
        align="center"
        // border="1px"
        shadow="lg"
        rounded="xl"
        my={3}
        px={6}
        py={8}
        justify="center">
        {avatar && (
          <Box
            display={["none", "block"]}
            w={1/3}
          >
            <Image
              as={GatsbyImage}
              image={getImage(avatar)}
              alt={author}
              w="70"
              minW="70"
              mr={4}
            />
          </Box>
        )}
        <Box>
          <BioContent />
        </Box>
      </Flex>
  )
}

const myBioQuery = graphql`
  query MyBioQuery {
    site {
      siteMetadata {
        author
      }
    }
    avatar: file(absolutePath: { regex: "/avatar.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(width: 70, placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

export default Bio
