import React from "react"

function DebugData({data}) {
  return (
    <div>
      <code>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </code>
    </div>
  )
}

export default DebugData
