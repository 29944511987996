import React from "react"
import { Divider, Heading as ChakraUiHeading, Link, Text, Code as ChakraUiCode } from "@chakra-ui/react"
import { css } from "@emotion/react"

const LinkIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 16 16"
    width="16"
    height="16"
    fill="currentcolor"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
    />
  </svg>
)

const Heading = (props) => (
  <>
    <ChakraUiHeading mb="1"
                     mt="10"
                     fontSize="xl"
                     {...props}
                     css={css({
                       a: {
                         visibility: `hidden`
                       },
                       ":hover a": {
                         visibility: `visible`
                       },
                       pointerEvents: `painted`
                     })}
    >
      <Link
        href={`#${props.id}`}
        display="inline-block"
        ml="-20px"
        pr="4px"
        color="primary"
        aria-label={props.children}
      >
        <LinkIcon />
      </Link>
      {props.children}
    </ChakraUiHeading>
    <Divider mb="3" />
  </>
)

function Highlight(props) {
  return <Text as="mark" px={1} {...props} />
}

function Code(props) {
  return <ChakraUiCode variant="outline" {...props} />
}

export {
  Heading,
  Highlight,
  Code
}
