import React from "react"
import { Box, Flex, Stack } from "@chakra-ui/react"
import ExternalLink from "./external-link"
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub"
import { AiFillTwitterCircle} from "@react-icons/all-files/ai/AiFillTwitterCircle"

export function ExternalLinkWithIcon({url, text, as, ...props}) {
  return (
      <ExternalLink  url={url} {...props}>
        <Flex align="center" justify="center"><Box as={as} mr="1" />{text}</Flex>
      </ExternalLink>
  )
}

export function GitHubLink({url, text='GitHub', ...props}) {
  return <ExternalLinkWithIcon url={url} text={text} as={AiFillGithub} {...props} />
}

export function TwitterLink({ url, text = 'Twitter', ...props }) {
  return <ExternalLinkWithIcon url={url} text={text} as={AiFillTwitterCircle} {...props}/>
}
