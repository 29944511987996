import React from "react"
import { Tag } from "@chakra-ui/react"

function PostTags({ tags }) {
  return (
    <>
      {tags && tags.map((tag, index) => (<Tag mr={1} key={index}>{tag}</Tag>))}
    </>
  )
}

export default PostTags
