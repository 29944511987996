import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { Highlight } from "./typography"
import { GitHubLink, TwitterLink } from "./ui"

export default function Bio() {
  return (
    <Box>
      👋 Hi! <Highlight>Welcome</Highlight> to my blog. I'm Kajan, a <Highlight>Software Engineer</Highlight> focusing
      on <Highlight>ASP.NET Core, EF Core and JavaScript(Vue, React)</Highlight> stack. I am <Highlight>thankful</Highlight> to
      the internet community for helping me out on various occasions 🙏😘. I hope to <Highlight>give back</Highlight> to
      the community by sharing my experience, and knowledge.
      <Flex mt="3">
        <TwitterLink mr={3} url="https://twitter.com/gm_k4j4n" />
        <GitHubLink url="https://github.com/kajanm" />
      </Flex>
    </Box>
  )
}
