import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Link as ChakraUiLink } from "@chakra-ui/react"

function Link(props) {
  return (
    <ChakraUiLink as={GatsbyLink} {...props} textDecoration="underline" />
  )
}

export default Link
